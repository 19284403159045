import React from 'react'
import {
    Box,
} from '@chakra-ui/react'
import Header from './header'
import Banner from './banner'
import backgroundImage from '../../assets/background.png'

const Navigation = () => {
    return (
        <Box fontFamily='IBM Plex Sans' backgroundImage={backgroundImage} backgroundSize='cover'>
            <Header />
            <Banner />
        </Box>
    )
}

export default Navigation