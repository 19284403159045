import React from 'react'
import {
    Box,
    Text,
    Flex,
    Image
} from '@chakra-ui/react'
import chiwa from '../../assets/chiwa.svg'
import chiwaSmall from '../../assets/chiwaSmall.svg'
import chiwaLogo from '../../assets/chiwaLogo.svg'
import gamestop from '../../assets/gamestop.svg'
import pips from '../../assets/pips.svg'
import pipsLogo from '../../assets/pipsLogo.svg'

const Businesses = () => {
    return (
        <Box
            mr={{base: '20px', md: '65px', xl: '65px'}}
            ml={{base: '20px', md: '65px', xl: '65px'}}
            mb='100px'
        >
            <Text
                fontSize={{base: '30px', md: '55px', xl: '55px'}}
                fontWeight='600'
                textAlign='center'
                pb='80px'
            >
                Join over 6,000+ businesses 
            </Text>

            {/* picures */}
            <Flex gap='100px' justifyContent='center' direction={{base: 'column', md: 'row', xl: 'row'}}>
                {/* first column */}
                <Flex direction='column' gap='40px'  alignItems={{base: 'center', md: 'inherit', xl: 'inherit'}} >

                    {/* Chiwa box */}
                    <LargeBox
                        name='Mchiwa'
                        shop='Chiwa Outlook'
                        logo={chiwaLogo}
                        image={chiwa}
                    />

                    {/* Chiwa desc */}
                    <SmallBox 
                        name='Mchiwa'
                        shop='Chiwa Outlook'
                        image={chiwaSmall}
                        desc='“ Hii app ya kuza imenisaidia sana kuweza kutambua faida ninayoipata kwa uharaka. 
                        Pia kipengele cha kuona ukuaji wa biashara kinanipa moyo na jitihada za kuendelea kuipambania biashara yangu”'
                    />
                </Flex>

                <Flex gap='40px' direction='column' alignItems={{base: 'center', md: 'inherit', xl: 'inherit'}}>
                    {/* Gamestop */}
                    <SmallBox 
                        name='Emmanuel'
                        shop='Gamestop'
                        image={gamestop}
                        desc='“ Toka nmeanza kupokea malipo ya kidogo kidogo kutoka wateja wangu kupitia Swahilies, 
                        niweza kuwafikia wateja wengi sana na kuwawezesha weng kumiliki games wazipendao kwa kulipia kuanzia buku tu”'
                    />

                    {/* pips */}
                    <LargeBox
                        name='Herbert Katua'
                        shop='Pips_by_rae'
                        logo={pipsLogo}
                        image={pips}
                    />
                </Flex>
            </Flex>
        </Box>
    )
}

export default Businesses


const LargeBox = (data) => {
    return (
        <Box
            w={{base: '330px', md: '480px', xl: '480px'}}
            h={{base: '460px', md: '670px', xl: '670px'}}
            bg='white'
            border='2px'
            borderRadius='20px'
        >
            <Image src={data.image} w={{base: '350px', md: '500px', xl: '500px'}} h={{base: '370px', md: '550px', xl: '550px'}} mb='20px'/>
            
            {/* Logo */}
            <Flex ml='10px' alignItems='center' gap='10px'>
                <Image src={data.logo} w={{base: '50px', md: '80px', xl: '80px'}} />

                {/* names */}
                <Flex direction='column'>
                    <Text fontWeight='600' fontSize={{base: '15px', md: 'inherit', xl: 'inherit'}}>{data.name}</Text>
                    <Text fontSize={{base: '12px', md: 'inherit', xl: 'inherit'}}>MD, {data.shop}</Text>
                </Flex>
            </Flex>
        </Box>
    )
}


const SmallBox = (data) => {
    return (
        <Box
            h={{base: '250px', md: '250px', xl: '250px'}}
            w={{base: '330px', md: '480px', xl: '480px'}}
            bg='white'
            border='2px'
            borderRadius='20px'
            p='25px'
        >
            {/* chiwa pic */}
            <Flex alignItems='center' gap='10px' pb='35px'>
                <Image src={data.image} w='60px' />
                <Flex direction='column'>
                    <Text fontWeight='600'>{data.name}</Text>
                    <Text fontSize='13px'>MD, {data.shop}</Text>
                </Flex>
            </Flex>

            {/* Chiwa desc */}
            <Text fontSize={{base: '13px', md: 'inherit', xl: 'inherit'}}>
                {data.desc}
            </Text>
        </Box>
    )
}