import React from 'react'
import {
    Box,
    Text,
    Flex,
    Image,
    Center
} from '@chakra-ui/react'
import card from '../../assets/card.svg'
import larby from '../../assets/larby.svg'
import api from '../../assets/api.svg'

const Payments = () => {
    return (
        <Box
            mt='42px'
            mr={{base: '20px', md: '60px', xl: '65px'}}
            ml={{base: '20px', md: '60px', xl: '65px'}}
            bg='#BD9EFF'
            pr={{base: '10px', md: '50px', xl: '50px'}}
            pl={{base: '10px', md: '50px', xl: '50px'}}
            pt={{base: '10px', md: '70px', xl: '70px'}}
            borderRadius='20px'
            pb={{base: '0px', md: '20px', xl: '20px'}}
            opacity='1'
            mb='80px'
        >
            {/* Header text */}
            <Text
                fontSize='49px'
                fontWeight='600'
                pb='40px'
                display={{base: 'none', md: 'inherit', xl: 'inherit'}}
            >
                We have you covered when it <br /> comes to payments
            </Text>

            <Text
                fontSize='25px'
                fontWeight='600'
                pb={{base: '15px', md: '40px', xl: '40px'}}
                display={{base: 'inherit', md: 'none', xl: 'none'}}
            >
                We have you covered when it comes to payments
            </Text>

            {/* Desc text */}
            <Text
                color='#383838'
                fontSize={{base: '10px', md: '20px', xl: '20px'}}
                pb={{base: '40px', md: '80px', xl: '80px'}}
            >
                We have made it easy for your business to accept payments from your customers. 
                With our secure payment gateway, you can accept credit card, debit card, 
                and mobile payments with ease.
            </Text>

            {/* features flex */}
            <Flex pb='60px' gap={{base: '20px', md: '0px', xl: '0px'}} justifyContent='space-between' direction={{base: 'column', md: 'row', xl: 'row'}}>
                <FeatureBox 
                    title='Accept Payments' 
                    firstDesc='Local mobile and' 
                    secondDesc='international card payments' 
                    image={card}
                />
                {/*  */}
                <FeatureBox 
                    title='Layby Payments' 
                    firstDesc='Increase sales by accepting' 
                    secondDesc='installment payments' 
                    image={larby}
                />
                {/*  */}
                <FeatureBox 
                    title='Payment-API' 
                    firstDesc='Easily integrate our API to' 
                    secondDesc='accept online payments' 
                    image={api}
                />
            </Flex>
        </Box>
    )
}

export default Payments


const FeatureBox = (data) => {
    return (
        <Box
            w={{base: '100%', md: '350px', xl: '370px'}}
            h={{base: '150px', md: '210px', xl: '210px'}}
            bg='white'
            border='2px'
            borderRadius='20px'
            pt='17px'
            pr='20px'
            pl='20px'
            alignItems='center'
        >
            <Center>
                <Image src={data.image} pb='4px' w={{base: '30px', md: 'inherit', xl: 'inherit'}} />
            </Center>
            <Text
                fontWeight='500'
                fontSize={{base: '16px', md: '23px', xl: '23px'}}
                pb={{base: '6px', md: '12px', xl: '12px'}}
                textAlign='center'
            >
                {data.title}
            </Text>
            <Text
                color='#383838'
                fontSize={{base: '11px', md: '16px', xl: '16px'}}
                textAlign='center'
            >
                {data.firstDesc} <br /> {data.secondDesc}
            </Text>
        </Box>
    )
}