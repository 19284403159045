import React from 'react'
import {
    Box,
    Flex,
    Text,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import logo from '../../assets/logo.svg';

const Header = () => {
    return (
        <Box pt={{base: '10px', md: '30px', xl: '30px'}} pl={{base: '15px', md: '60px', xl: '60px'}}>
            <Flex gap='224px' alignItems='center'>
                <Image src={logo} w={{base: '90px', md: 'inherit', xl: 'inherit'}} />
                <Flex gap='87px' display={{base: 'none', md: 'inherit', xl: 'inherit'}}>
                    <HeaderText text='About Us' link='https://pay.swahilies.com/about-us'/>
                    <ProductSection />
                    <HeaderText text='Contact Us' link='https://pay.swahilies.com/contact-us'/>
                    <HeaderText text='Blog' link='https://medium.com/@jhaulay' />
                </Flex>
            </Flex>
        </Box>
    )
}

export default Header


const HeaderText = (data) => {
    return (
        <Link href={data.link} textDecoration='none' textDecorationLine='none'>
            <Text
                fontSize='13px'
                fontWeight='600'
            >
                {data.text}
            </Text>
        </Link>
        
    )
}

const ProductSection = () => {
    return (
        <Menu>
            <MenuButton>
                <Text
                    fontSize='13px'
                    fontWeight='600'
                >
                    Products
                </Text>
            </MenuButton>
            <MenuList pr='30px' pl='30px' pt='10px' pb='10px'>
                <MenuItem>
                    <HeaderText text='BookKeeping' link='#'/>
                </MenuItem>
                <MenuItem>
                    <HeaderText text='Payments' link='https://pay.swahilies.com'/>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}