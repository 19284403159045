import React from 'react'
import {
    Box,
    Text,
    Flex,
    Link,
} from '@chakra-ui/react'

const Footer = () => {
    return (
        <Box
            
            
        >
            <Flex gap={{base: '20px', md: '150px', xl: '150px'}} mr='80px' ml='80px' mb='80px' direction={{base: 'column', md: 'row', xl: 'row'}}>
                {/* logo */}
                <Text
                    color='#F6A414'
                    fontWeight='900'
                    fontSize='36px'
                >
                    Swahilies
                </Text>

                {/* links */}
                <Flex justifyContent='space-between' w='100%' pt='20px' gap={{base: '50px', md: '0px', xl: '0px'}} direction={{base: 'column', md: 'row', xl: 'row'}}>
                    <FooterLinks 
                        header='Company'
                        items={[['About us', 'https://pay.swahilies.com/about-us'], ['Contact Us', 'https://pay.swahilies.com/contact-us'], ['Blog', 'https://medium.com/@jhaulay']]}
                    />
                    <FooterLinks 
                        header='Products'
                        items={[['Kuza Business', 'https://linktr.ee/kuzaBusiness'], ['Swahilies', 'https://apps.apple.com/tz/app/swahilies/id1552941234'], ['Swahilies Pay', 'https://rigorous-trade-f5f.notion.site/Ndugu-Mfanyabiashara-Karibu-Swahilies-8e60501a8918469e948ebb1b0c5f5b1e']]}
                    />
                    <FooterLinks 
                        header='Legal'
                        items={[['Terms of service', 'https://swahilies.notion.site/Kuza-Privacy-Policy-c944bf1b0e95441b840db40d9ef721a7'], ['Privacy policy', 'https://swahilies.notion.site/Kuza-Privacy-Policy-c944bf1b0e95441b840db40d9ef721a7'], ['Cookies policy', 'https://swahilies.notion.site/Kuza-Privacy-Policy-c944bf1b0e95441b840db40d9ef721a7']]}
                    />
                    <FooterLinks 
                        header='Social media'
                        items={[['Instagram', 'https://www.instagram.com/kuzabusiness/'], ['Twitter', 'https://mobile.twitter.com/kuzabusiness'], ['Youtube', 'https://www.youtube.com/@kuzabusiness9095']]}
                    />
                </Flex>
            </Flex>
            {/* finish */}
            {/* <Box w='100%' display={{base: 'none', md: 'inherit', xl: 'inherit'}} border='1px' borderColor='rgba(246, 164, 20, 0.34)' mb='30px'></Box> */}

            {/*  */}
            <Text
                ml='80px'
                color='#717173'
                fontSize='15px'
                mb='40px'
            >
                2023 Swahilies. All rights reselved.
            </Text>
        </Box>
    )
}

export default Footer


const FooterLinks = (data) => {
    return (
        <Flex gap='30px' direction='column'>
            <Text
                fontWeight='700'
                fontSize='20px'
            >
                {data.header}
            </Text>
            {data.items.map((row, i)=>
                <Link href={row[1]} isExternal>
                    <Text
                        color='#717173'
                        fontSize='13px'
                    >
                        {row[0]}
                    </Text>
                </Link>
            )}
            
        </Flex>
    )
}