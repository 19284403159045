import * as React from 'react'
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import Navigation from './components/navigation';
import Features from './components/features';
import Payments from './components/payments';
import Businesses from './components/businesses';
import FooterBanner from './components/footerBanner';
import Footer from './components/footer';
import JoinGroup from './components/group';

const AppWrapper = () => {
  let routes = useRoutes([
    { path: "/", element: 
    <>
      <Navigation />
      <Features />
      <Payments />
      <Businesses />
      <FooterBanner />
      <Footer />
    </> },
    { path: "/merchantgroup", element: <JoinGroup /> },
  ]);
  return routes;
};

function App() {
  return (
    <Router>
      <ChakraProvider>
        <AppWrapper />
      </ChakraProvider>
    </Router>
  );
}

export default App;
