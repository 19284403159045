import React from 'react'
import { Box, Text, Image, Center, Link } from '@chakra-ui/react'
import logo from '../../assets/logo.svg'
import playstore from '../../assets/playstore.svg';
import appstore from '../../assets/appstore.svg';

const JoinGroup = () => {

    return (
        <Box
            p='20px'
            fontFamily='IBM Plex Sans, sans-serif'
        >
            <Center pb='200px'>
                <Image src={logo} />
            </Center>

            {/*  */}
            <Text fontWeight='bold' fontSize='25px' mb='50px' textAlign='center'>
                Kujiunga na kundi download application
            </Text>

            {/*  */}
            <Center  mb={{base: '20px', md: '0px', xl: '0px'}}>
                <Link href='https://play.google.com/store/apps/developer?id=Swahilies&hl=en&gl=US' isExternal>
                    <Image src={playstore} h={{base: '40px', md: '42px', xl: '42px'}} w={{base: '', md: '170px', xl: '170px'}}/>
                </Link>
            </Center>
            <Center  mb={{base: '20px', md: '0px', xl: '0px'}}>
                <Link href='https://apps.apple.com/tz/app/swahilies/id1552941234' isExternal>
                    <Image src={appstore} h={{base: '40px', md: '42px', xl: '42px'}} w={{base: '', md: '170px', xl: '170px'}}/>
                </Link>
            </Center>
        </Box>
    )
}

export default JoinGroup