import React from 'react'
import {
    Box,
    Text,
    Flex,
    Image,
    Link,
} from '@chakra-ui/react'
import playstore from '../../assets/playstore.svg';
import appstore from '../../assets/appstore.svg';
import mediumPhone from '../../assets/mediumPhone.svg'

const FooterBanner = () => {
    return (
        <Box
            mt='42px'
            mr={{base: '20px', md: '60px', xl: '65px'}}
            ml={{base: '20px', md: '60px', xl: '65px'}}
            bg='#FFFCEA'
            pr={{base: '10px', md: '80px', xl: '80px'}}
            pl={{base: '10px', md: '80px', xl: '80px'}}
            pt={{base: '10px', md: '70px', xl: '70px'}}
            borderRadius='20px'
            mb={{base: '50px', md: '100px', xl: '100px'}}
        >
            {/*  */}
            <Flex alignItems='center' justifyContent='space-between'>
                <Box>
                    <Text
                        fontWeight='700'
                        fontSize={{base: '20px', md: '35px', xl: '35px'}}
                        pb='40px'
                        display={{base: 'none', md: 'inherit', xl: 'inherit'}}
                    >
                        Don't let the daily grind of <br /> running a business hold you back
                    </Text>
                    <Text
                        fontWeight='700'
                        fontSize={{base: '20px', md: '35px', xl: '35px'}}
                        pb='20px'
                        display={{base: 'inherit', md: 'none', xl: 'none'}}
                    >
                        Don't let the daily grind of running a business hold you back
                    </Text>

                    {/*  */}
                    <Text pb='40px' fontSize='17px' display={{base: 'none', md: 'inherit', xl: 'inherit'}}>
                        Sign up for Kuzabusiness by Swahilies, today and take <br /> control of your business's success.
                    </Text>
                    <Text pb='40px' fontSize='13px' display={{base: 'inherit', md: 'none', xl: 'none'}}>
                        Sign up for Kuzabusiness by Swahilies, today and take control of your business's success.
                    </Text>

                    {/* store icons */}
                    <Flex justifyContent={{base: 'center', md: 'inherit', xl: 'inherit'}} mb={{base: '20px', md: '0px', xl: '0px'}}>
                        <Link href='https://play.google.com/store/apps/developer?id=Swahilies&hl=en&gl=US' isExternal>
                            <Image src={playstore} h={{base: '20px', md: '42px', xl: '42px'}} w={{base: '', md: '170px', xl: '170px'}}/>
                        </Link>
                        <Link href='https://apps.apple.com/tz/app/swahilies/id1552941234' isExternal>
                            <Image src={appstore} h={{base: '20px', md: '42px', xl: '42px'}} w={{base: '', md: '170px', xl: '170px'}}/>
                        </Link>
                    </Flex>
                </Box>
                <Image src={mediumPhone} w='250px' mr='120px' display={{base: 'none', md: 'inherit', xl: 'inherit'}}/>
            </Flex>
        </Box>
    )
}

export default FooterBanner