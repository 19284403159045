import React from 'react'
import {
    Box,
    Text,
    Flex,
    Image,
    Center,
} from '@chakra-ui/react'
import stock from '../../assets/stock.svg'
import sales from '../../assets/sales.svg'
import dept from '../../assets/dept.svg'
import management from '../../assets/managment.svg'

const Features = () => {
    return (
        <Box
            mt='42px'
            mr={{base: '20px', md: '65px', xl: '65px'}}
            ml={{base: '20px', md: '65px', xl: '65px'}}
            bg='#FFFCEA'
            pr={{base: '20px', md: '80px', xl: '80px'}}
            pl={{base: '20px', md: '80px', xl: '80px'}}
            pt={{base: '20px', md: '70px', xl: '70px'}}
            borderRadius='30px'
            pb='20px'
        >
            {/* header text */}
            <Text
                fontSize={{base: '30px', md: '57px', xl: '57px'}}
                fontWeight='600'
                lineHeight={{base: '35px', md: '80px', xl: '80px'}}
                pb='55px'
                display={{base: 'none', md: 'inherit', xl: 'inherit'}}
            >
                All that you need to stay organised <br /> and on-top of your game 
            </Text>
            <Text
                fontSize={{base: '30px', md: '57px', xl: '57px'}}
                fontWeight='600'
                lineHeight={{base: '35px', md: '80px', xl: '80px'}}
                pb='25px'
                display={{base: 'inherit', md: 'none', xl: 'none'}}
            >
                All that you need to stay organised and on-top of your game 
            </Text>

            {/* desc text */}
            <Text
                fontWeight='500'
                fontSize={{base: '13px', md: '22px', xl: '22px'}}
                pb='80px'
                display={{base: 'none', md: 'inherit', xl: 'inherit'}}
            >
                We understand the unique challenges that SMEs face, which is why we've developed a suite of tools <br /> to help you manage your business.
            </Text>
            <Text
                fontWeight='500'
                fontSize={{base: '13px', md: '22px', xl: '22px'}}
                pb='80px'
                display={{base: 'inherit', md: 'none', xl: 'none'}}
            >
                We understand the unique challenges that SMEs face, which is why we've developed a suite of tools to help you manage your business.
            </Text>

            {/* First row */}
            <RightSide
                bg='white'
                header='Stock inventory'
                image={stock}
                desc="Keep track of your stock levels and see what's selling fast and slow with our real-time inventory management system"
                textColor='#6F6F6F'
                borderColor='black'
            />

            {/* Second row */}
            <LeftSide
                bg='#E8F2FF'
                header='Sales and Expenses'
                image={sales}
                desc="Our platform makes it easy to track your sales and expenses, so you can see exactly how your business is performing at any given time"
                textColor='#2D85F9'
                borderColor='#2D85F9'
            />
            

            {/* Third row */}
            <RightSide
                bg='#FFDBDB'
                header='Debt-collection'
                image={dept}
                desc="We've built powerful tools to help you collect outstanding debts from your customers, so you can keep your cash flow healthy and your business growing"
                textColor='#CC3434'
                borderColor='#CC3434'
            />

            
            {/* Forth row */}
            <LeftSide
                bg='#E2FFE7'
                header='Customer Management'
                image={management}
                desc="Keep all your customer information in one place, so you can easily communicate with them and build long-lasting relationships."
                textColor='#12B62E'
                borderColor='#12B62E'
            />
        </Box>
    )
}

export default Features


const RightSide = (data) => {
    return (
        <Flex 
            alignItems='center' 
            gap={{base: '10px', md: '60px', xl: '60px'}} 
            mb='68px' 
            mr={{base: '0px', md: '60px', xl: '60px'}}
            ml={{base: '0px', md: '60px', xl: '60px'}}
            direction={{base: 'row', md: 'row', xl: 'row'}}
        >
            <Box
                h={{base: '90px', md: '150px', xl: '150px'}}
                w={{base: '400px', md: '360px', xl: '360px'}}
                bg={data.bg}
                border='2px'
                borderRadius='12px'
                borderColor={data.borderColor}
                pt={{base: '13px', md: '28px', xl: '28px'}}
            >
                <Center>
                    <Image src={data.image} pb={{base: '10px', md: '20px', xl: '20px'}} h={{base: '45px', md: '53px', xl: '53px'}} w='53px' />
                </Center>
                <Text
                    textAlign='center'
                    fontWeight='600'
                    color={data.textColor}
                    fontSize={{base: '12px', md: 'inherit', xl: 'inherit'}}
                >
                    {data.header}
                </Text>
            </Box>
            <Text
                color='#6F6F6F'
                fontSize={{base: '10px', md: '20px', xl: '20px'}}
            >
                {data.desc}
            </Text>
        </Flex>
    )
}


const LeftSide = (data) => {
    return (
        <Flex 
            alignItems='center' 
            gap={{base: '10px', md: '60px', xl: '60px'}} 
            mb='68px' 
            mr={{base: '0px', md: '60px', xl: '60px'}}
            ml={{base: '0px', md: '60px', xl: '60px'}}
            direction={{base: 'row', md: 'row', xl: 'row'}}
        >
            <Text
                color='#6F6F6F'
                fontSize={{base: '10px', md: '20px', xl: '20px'}}
            >
                {data.desc}
            </Text>
            <Box
                h={{base: '90px', md: '150px', xl: '150px'}}
                w={{base: '400px', md: '360px', xl: '360px'}}
                bg={data.bg}
                border='2px'
                borderRadius='12px'
                borderColor={data.borderColor}
                pt={{base: '13px', md: '28px', xl: '28px'}}
            >
                <Center>
                    <Image src={data.image} pb={{base: '10px', md: '20px', xl: '20px'}} h={{base: '45px', md: '53px', xl: '53px'}} w='53px' />
                </Center>
                <Text
                    textAlign='center'
                    fontWeight='600'
                    color={data.textColor}
                    fontSize={{base: '10px', md: 'inherit', xl: 'inherit'}}
                >
                    {data.header}
                </Text>
            </Box>
        </Flex>
    )
}