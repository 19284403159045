import React from 'react'
// import { m } from 'framer-motion';
import {
    Box,
    Flex,
    Text,
    Image,
    Link,
} from '@chakra-ui/react'
import playstore from '../../../assets/playstore.svg';
import appstore from '../../../assets/appstore.svg';
import smallPhone from '../../../assets/smallPhone.svg';
import largePhone from '../../../assets/largePhone.svg';

const Banner = () => {
    return (
        <Box pt='30px' pl={{base: '20px', md: '100px', xl: '100px'}}>
            <Flex alignItems='center' gap='8px' direction={{ base: 'column', md: 'row', xl: 'row' }}>
                <Flex direction='column'>

                    {/* Main text */}
                    <Text
                        fontWeight='bold'
                        fontSize='60px'
                        pb='46px'
                        display={{base: 'none', md: 'block', xl: 'block'}}
                    >
                        Everything you need to <br /> grow your business
                    </Text>

                    <Text
                        fontWeight='bold'
                        fontSize='30px'
                        pb='26px'
                        display={{base: 'block', md: 'none', xl: 'none'}}
                    >
                        Everything you need to  grow your business
                    </Text>
                    
                    <Flex gap='60px' direction={{ base: 'column', md: 'row', xl: 'row' }}>
                        <Flex direction='column'>
                            {/* Desc text */}
                            <Text
                                pb='86px'
                                fontSize='20px'
                                fontWeight='500'
                                display={{base: 'none', md: 'block', xl: 'block'}}
                            >
                                A suite of tools to help SME’s track, assess and manage <br /> the growth of their businesses.
                            </Text>
                            <Text
                                pb='46px'
                                fontSize='14px'
                                fontWeight='500'
                                display={{base: 'block', md: 'none', xl: 'none'}}
                            >
                                A suite of tools to help SME’s track, assess and manage the growth of their businesses.
                            </Text>

                            <Flex alignItems='center' mb='30px' justifyContent='center' display={{base: 'inherit', md: 'none', xl: 'none'}}>
                                <Image src={smallPhone} h='150px' mt='60px'  />
                                <Image src={largePhone} h='230px'  />
                            </Flex>

                            {/* store icons */}
                            <Flex justifyContent={{base: 'center', md: 'inherit', xl: 'inherit'}}>
                                <Link href='https://play.google.com/store/apps/developer?id=Swahilies&hl=en&gl=US' isExternal>
                                    <Image src={playstore} h={{base: '20px', md: '42px', xl: '42px'}} w={{base: '', md: '170px', xl: '170px'}}/>
                                </Link>
                                <Link href='https://apps.apple.com/tz/app/swahilies/id1552941234' isExternal>
                                    <Image src={appstore} h={{base: '20px', md: '42px', xl: '42px'}} w={{base: '', md: '170px', xl: '170px'}}/>
                                </Link>
                            </Flex>
                        </Flex>

                        {/* Small phone */}
                        <Image src={smallPhone} display={{base: 'none', md: 'inherit', xl: 'inherit'}} />
                    </Flex>
                </Flex>

                {/* right side image */}
                <Image src={largePhone} h='650px' display={{base: 'none', md: 'inherit', xl: 'inherit'}} />
            </Flex>
        </Box>
    )
}

export default Banner